import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

import IconFB from '../components/Icons/facebook';
import IconTwitter from '../components/Icons/twitter';
import IconMailSm from '../components/Icons/mail-sm';

import { BVOG_URL } from '../constants';
import { getDateAndTime } from '../utils/common';

const Hashtags = ({ hashtag }) => (
  <div className="tags">
    {hashtag.split(',').map((tag, index) => (
      <Link key={index} to={`/search?query=${tag.trim()}`} state={{ searchQuery: tag.trim() }}>
        <div>#{tag.trim()}</div>
      </Link>
    ))}
  </div>
);

const PostStipendium = ({ node }) => (
  <article className="article" id={node.link}>
    <div className="container">
      <div className="post-page__wrapper">
        <div className="post-page__social">
          <div>
            <FacebookShareButton url={`${BVOG_URL}/stipendien/${node.link}`}>
              <IconFB />
            </FacebookShareButton>
          </div>
          <div>
            <TwitterShareButton url={`${BVOG_URL}/stipendien/${node.link}`}>
              <IconTwitter />
            </TwitterShareButton>
          </div>
          <div>
            <EmailShareButton url={`${BVOG_URL}/stipendien/${node.link}`}>
              <IconMailSm />
            </EmailShareButton>
          </div>
        </div>
        <div className="post-page__content">
          {node.image && (
            <div className="post-page__content__left">
              <Img
                fluid={node.image.fluid}
                alt={node.beitragsname}
                style={{ width: 250, height: 250 }}
              />
              {node.hashtag && <Hashtags hashtag={node.hashtag} />}
            </div>
          )}

          <div className="post-page__content__right">
            <Link to={`/stipendien/${node.link}`}>
              <h3 className="post-page__content__right__date">{getDateAndTime(node.beginn)}</h3>
              <h3 className="post-page__content__right__location">{node.ort}</h3>
              <h3 className="post-page__content__right__title">{node.beitragsname}</h3>

              <div dangerouslySetInnerHTML={{ __html: node.frontText }} />
            </Link>

            {!node.image && node.hashtag && <Hashtags hashtag={node.hashtag} />}
          </div>
        </div>
      </div>
    </div>
  </article>
);

export default PostStipendium;
